import {
  CompanySubscriptionDetail,
  CompanySubscriptionSummary,
  Res,
} from 'common/types/responses'
import dataRelay from 'data-relay'
import { Project } from 'common/project'
export function getEventData(
  sub: CompanySubscriptionDetail | CompanySubscriptionSummary,
  user: Res['user'],
) {
  const plan = user?.products.find((p) => `${p.id}` === `${sub.plan_id}`)

  const eventData = {
    event: 'Sale',
    propertyString1: 'INVOICE',
    propertyString2: plan?.billing_period === 2 ? 'year' : 'month',
    propertyString3: `${user.tenant_id}`,
    sku: plan?.name,
    source: user.partner_name,
    total:
      !!plan &&
      (plan?.billing_period === 2
        ? parseInt((plan.cost / 12) * 100)
        : parseInt(plan.cost * 100)),
  }
  return eventData
}
export default function (data: CompanySubscriptionDetail, user: Res['user']) {
  try {
    if (!Project.dataRelay) return
    const eventData = getEventData(data, user)
    dataRelay.sendEvent(eventData, { apiKey: Project.dataRelay })
  } catch (e) {}
}

export function trackCancelSubscription(
  data: CompanySubscriptionDetail | CompanySubscriptionSummary,
  user: Res['user'],
) {
  try {
    if (!Project.dataRelay) return
    const eventData = getEventData(data, user)
    dataRelay.sendEvent(
      {
        ...eventData,
        event: 'Cancel',
        total: -(eventData.total || 0),
      },
      { apiKey: Project.dataRelay },
    )
  } catch (e) {}
}
