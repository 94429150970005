import { Project } from 'common/project'

export default function money(value: number) {
  if (value) {
    return value?.toLocaleString(Project.locale, {
      currency: Project.currency,
      style: 'currency',
    })
  }
  return (0).toLocaleString(Project.locale, {
    currency: Project.currency,
    style: 'currency',
  })
}
